html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}

.glide__slides--dragging {
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}

.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
}

.glide__arrow:focus {
  outline: none;
}

.glide__arrow:hover {
  border-color: white;
}

.glide__arrow--left {
  left: 2em;
}

.glide__arrow--right {
  right: 2em;
}

.glide__arrow--disabled {
  opacity: 0.33;
}

.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%);
}

.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em;
}

.glide__bullet:focus {
  outline: none;
}

.glide__bullet:hover, .glide__bullet:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5);
}

.glide__bullet--active {
  background-color: white;
}

.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.glide .glide__track .glide__slides {
  align-items: center;
}

.glide .glide__track .glide__slides .glide__slide {
  text-align: center;
  height: 100%;
}

body {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-image: url(/build/assets/images/fdaccueil.jpg);
  background-size: cover;
  background-attachment: fixed;
}

.main-container .header-container {
  min-height: 100vh;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 940px) {
  .main-container .header-container.sticky {
    justify-content: flex-end;
  }
  .main-container .header-container.sticky #header {
    align-items: stretch;
    position: fixed;
    width: 100%;
    justify-content: center;
    max-width: none;
    background-color: rgba(255, 255, 255, 0.7);
    top: 0;
    z-index: 100;
  }
  .main-container .header-container.sticky #header .logo-container {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 4px;
  }
  .main-container .header-container.sticky #header .logo-container .main-logotype {
    max-height: 46px;
  }
  .main-container .header-container.sticky #header .main-navigation {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .main-container .header-container.sticky #header .main-navigation ul {
    padding: 0;
    height: 100%;
  }
  .main-container .header-container.sticky #header .main-navigation ul li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 940px) {
  .main-container .header-container #header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    max-width: 1200px;
    margin: 0 auto;
  }
  .main-container .header-container #header .logo-container .main-logotype {
    width: auto;
  }
  .main-container .header-container #header .main-navigation ul {
    display: flex;
  }
  .main-container .header-container #header .main-navigation ul li {
    color: whitesmoke;
    background-color: rgba(4, 42, 66, 0.8);
    display: flex;
    align-items: center;
  }
  .main-container .header-container #header .main-navigation ul li:hover, .main-container .header-container #header .main-navigation ul li:active {
    font-weight: normal;
  }
  .main-container .header-container #header .main-navigation ul li:hover {
    background-color: #A2192A;
  }
  .main-container .header-container #header .main-navigation ul li:active {
    background-color: #042a42;
  }
  .main-container .header-container #header .main-navigation ul li a {
    color: whitesmoke;
  }
}

.main-container .main-title {
  padding: 16px;
  margin: auto;
  max-width: 1200px;
  width: calc(100% - 32px);
  align-self: flex-end;
}

@media screen and (min-width: 940px) {
  .main-container .main-title {
    display: flex;
    justify-content: flex-end;
  }
}

.main-container .main-title h1 {
  font-weight: normal;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.5);
  color: #003854;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 40px;
  text-align: center;
  padding: 8px;
}

@media screen and (min-width: 940px) {
  .main-container .main-title h1 {
    text-align: right;
    margin-right: 40px;
  }
}

.main-container .main-title h1 em {
  font-weight: bold;
  font-style: normal;
}

.main-container .content-container .homepage-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container {
    padding: 0;
  }
}

.main-container .content-container .homepage-container h2 {
  color: #A2192A;
  font-size: 20px;
  text-transform: uppercase;
  padding: 0 0 36px 0;
}

.main-container .content-container .homepage-container .fiches-container {
  box-sizing: border-box;
  width: 100%;
  margin: auto;
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container .fiches-container {
    padding: 32px;
  }
}

.main-container .content-container .homepage-container .fiches-container .fiches {
  display: flex;
  flex-wrap: wrap;
}

.main-container .content-container .homepage-container .fiches-container .fiches > * {
  width: 100%;
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container .fiches-container .fiches > * {
    width: calc(100% / 4);
  }
}

.main-container .content-container .homepage-container .fiches-container .fiches .text-container {
  width: calc(100%);
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container .fiches-container .fiches .text-container {
    width: calc(100% / 2);
  }
}

.main-container .content-container .homepage-container .fiches-container .fiches .fiche a {
  display: flex;
  align-items: center;
  padding: 8px;
}

.main-container .content-container .homepage-container .fiches-container .fiches .fiche a.white {
  color: whitesmoke;
  font-weight: bolder;
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container .fiches-container .fiches .fiche a {
    flex-direction: column;
  }
}

.main-container .content-container .homepage-container .fiches-container .fiches .fiche a img.icon {
  height: 32px;
  width: 32px;
  padding-right: 8px;
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container .fiches-container .fiches .fiche a img.icon {
    display: none;
  }
}

.main-container .content-container .homepage-container .fiches-container .fiches .fiche a img.illustration {
  display: none;
  width: 100%;
  max-width: 300px;
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container .fiches-container .fiches .fiche a img.illustration {
    display: block;
  }
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container .fiches-container .fiches .fiche a .filename {
    display: none;
  }
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container .text {
    max-width: 400px;
    padding: 32px;
  }
}

.main-container .content-container .homepage-container .text p {
  color: #042A42;
  font-size: 18px;
  padding: 0;
}

.main-container .content-container .homepage-container .text .chapo {
  color: #A2192A;
  font-size: 20px;
  text-transform: uppercase;
  padding: 0 0 36px 0;
}

.main-container .content-container .homepage-container .text .content {
  padding-bottom: 22px;
}

.main-container .content-container .homepage-container#description-container {
  background-color: rgba(245, 245, 245, 0.9);
}

.main-container .content-container .homepage-container#description-container .description-container-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container#description-container .description-container-content {
    padding: 0 0 0 32px;
  }
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container#description-container .description-container-content {
    flex-direction: row;
  }
  .main-container .content-container .homepage-container#description-container .description-container-content .text {
    margin-right: 16px;
  }
}

.main-container .content-container .homepage-container#description-container .description-container-content .illustration img {
  height: 100%;
  width: 100%;
}

.main-container .content-container .homepage-container#gamme-escaliers-container {
  background-color: rgba(4, 42, 66, 0.6);
}

.main-container .content-container .homepage-container#garde-corps-container {
  background-color: rgba(245, 245, 245, 0.9);
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container#garde-corps-container .fiches-container .fiches {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container#garde-corps-container .fiches-container .fiches .text-container {
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }
}

.main-container .content-container .homepage-container#galerie-container {
  background-color: rgba(4, 42, 66, 0.9);
  color: whitesmoke;
  padding: 0;
  height: auto;
  max-height: 100vh;
  box-sizing: border-box;
}

.main-container .content-container .homepage-container#galerie-container h2 {
  color: whitesmoke;
  text-align: center;
  display: none;
}

.main-container .content-container .homepage-container#contact-container {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 32px 8px;
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container#contact-container {
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
  }
  .main-container .content-container .homepage-container#contact-container > div {
    width: 50%;
  }
}

.main-container .content-container .homepage-container#contact-container .contact-data {
  color: #042A42;
  font-size: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container#contact-container .contact-data {
    text-align: left;
  }
}

.main-container .content-container .homepage-container#contact-container .contact-data > * {
  padding-bottom: 16px;
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container#contact-container .contact-data {
    padding: 16px 0 16px 16px;
  }
}

.main-container .content-container .homepage-container#contact-container .contact-data .contact-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (min-width: 940px) {
  .main-container .content-container .homepage-container#contact-container .contact-data .contact-buttons {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.main-container .content-container .homepage-container#contact-container .contact-data .contact-buttons a {
  background-color: #042A42;
  color: whitesmoke;
  font-size: 18px;
  text-decoration: none;
  display: block;
  padding: 8px;
  text-align: center;
  border-radius: 8px;
  margin: 4px;
  text-transform: uppercase;
}

.main-container .footer-container {
  background-color: #042A42;
  color: whitesmoke;
  padding: 8px;
}

.main-container .footer-container a {
  color: whitesmoke;
}

.main-container .footer-container footer {
  text-align: center;
}

.main-container .footer-container footer ul li {
  padding-bottom: 4px;
}

.main-container .footer-container footer ul li .company-name {
  text-transform: uppercase;
}

.main-container .footer-container footer ul li.legals-mentions {
  padding-top: 8px;
  padding-bottom: 12px;
}

#header .logo-container {
  display: flex;
  justify-content: center;
  padding: 16px;
}

#header .logo-container .main-logotype {
  height: 20%;
  width: 20%;
}

#header .main-navigation ul {
  padding: 16px;
}

#header .main-navigation ul li {
  border-top: 1px rgba(0, 56, 84, 0.1) solid;
  background-color: rgba(255, 255, 255, 0.8);
}

#header .main-navigation ul li:hover, #header .main-navigation ul li:active {
  color: #a2192a;
  background-color: #a2192a;
  font-weight: bolder;
}

#header .main-navigation ul li:hover a, #header .main-navigation ul li:active a {
  color: whitesmoke;
}

#header .main-navigation ul li:last-child {
  border-bottom: 1px rgba(0, 56, 84, 0.1) solid;
}

#header .main-navigation ul li a {
  display: block;
  padding: 16px;
  color: #003854;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.page-legals {
  background-image: url("../images/fdaccueil.jpg");
  padding: 0 16px 64px 16px;
}

.page-legals .content {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 16px;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  margin: auto;
}

.page-legals .content h1 {
  font-size: 2rem;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 64px;
  font-weight: bolder;
  text-transform: uppercase;
}

.page-legals .content h2 {
  font-size: 1.5rem;
  margin-top: 32px;
  margin-bottom: 16px;
  text-align: center;
}

.page-legals .content p {
  margin-bottom: 16px;
}

.page-legals .content a {
  text-decoration: none;
  white-space: nowrap;
}

#popup_active {
  box-shadow: none;
  width: auto;
}

.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
}

/*
body {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;

  margin: 0;
  padding: 0;

  overflow-x: hidden;
}

.main-container {
  max-width: 1800px;
  margin: auto;
}

.header-container {
  background-image: url("../images/fdaccueil.jpg");
  background-attachment: scroll;
  background-position: center center;
  min-height: 100vh;

  header {
    &.sticky {
      position: fixed;
      top: 0;
      width: 100%;
      align-items: flex-start;

      #main-logotype {
        height: calc(256px / 6);
        width: calc(286px / 6);
      }
    }
  }

  .main-title {
    display: flex;
    justify-content: flex-end;
    padding-right: 330px;
    padding-top: 120px;
  }

  h1 {
    font-weight: normal;
    text-transform: uppercase;
    width: 592px;
    height: 174px;
    background-color: rgba(255, 255, 255, 0.5);
    color: #042A42;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 45px;
    text-align: right;
    padding: 0px 8px 0px 0px;

    em {
      font-weight: bold;
      font-style: normal;
    }
  }
}

header {
  //background-color: rgba(123, 215, 23, 0.1);

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;


  #main-logotype {
    width: 286px;
    height: 256px;
    padding: 12px;
  }

  nav {
    width: 1163px;
    background-color: rgba(4, 42, 66, 0.5);

    ul {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      height: 100%;

      li {
        list-style-type: none;
        text-transform: uppercase;
        color: #FFFFFF;
        text-align: center;
        font-size: 14px;
        font-family: Helvetica, Helvetica Neue, Arial, sans-serif;
        height: 100%;

        a {
          display: block;
          padding: 16px 24px;
          text-decoration: none;
          color: #ffffff;
          &:hover {
            background-color: #A2192A;
          }
        }
      }
    }
  }
}

#description-container {
  display: flex;

  .text {
    width: 30%;
    padding: 48px 120px 0 48px;

    p {
      color: #042A42;
      font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
      font-size: 18px;
      padding: 0px;
    }

    .chapo {
      color: #A2192A;
      font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
      font-size: 20px;
      text-transform: uppercase;
      padding: 0 0 36px 0;
    }

    .content {
      padding-bottom: 22px;
    }
  }

  .illustration {
    height: 680px;
    width: 70%;
    background: url("../images/presentation.jpg");
    background-size: cover;
    img {

    }
  }
}

#gamme-escaliers-container {
  min-height: 100vh;
  background-color: rgba(4, 42, 66, 0.5);
}

.footer-container {
  height: 62px;
  background-color: rgba(4, 42, 66, 0.5);

  footer {
    ul {
      text-transform: uppercase;
      display: flex;
      justify-content: center;

      li {
          list-style-type: none;
          padding: 8px 64px;
      }
    }
  }
}
*/
