.glide {
  .glide__track {
    .glide__slides {
      align-items: center;
      .glide__slide {
        text-align: center;
        //display: block;
        height: 100%;

        img {
          //object-fit: cover;
          //width: 100%;
          //height: 100%;
        }
      }
    }
  }
}
